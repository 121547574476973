const translation = {
    action: {
        addNew: '新規追加',
        add: '追加',
        create: '作成',
        createNew: '新規作成',
        edit: '編集',
        delete: '削除',
        cancel: 'キャンセル',
        update: '更新',
        save: '保存',
        saveChange: '変更を保存',
        saveLoader: 'ローダーを保存',
        saveChunks: 'チャンクを保存',
        show: '表示',
        copy: 'コピー',
        loading: '読み込み中',
        back: '戻る',
        skip: 'スキップ',
        close: '閉じる',
        yes: 'はい',
        no: 'いいえ',
        process: '処理',
        preview: 'プレビュー',
        log: 'ログ',
        clear: 'クリア',
        expand: '展開',
        info: '情報',
        dupplicate: '重複',
        view: '表示',
        import: 'インポート',
        ok: 'はい'
    },
    status: {
        empty: '空',
        sync: '同期',
        syncing: '同期中',
        stale: '古い',
        new: '新規'
    },
    message: {
        nodeOutdated: 'ノードバージョン {{oldVersion}} は古いです\n最新バージョン {{newVersion}} に更新してください',
        nodeVersionEmpty: 'ノードが古いです\n最新バージョン {{newVersion}} に更新してください',
        nodeDeprecating: 'このノードは次のリリースで廃止予定です。新しいノード に更新してください'
    },
    pagination: {
        page: 'ページ'
    },
    text: {
        all: 'すべて',
        none: 'なし',
        imageUrl: '画像URL',
        customImageUrl: 'カスタム画像URL',
        url: 'URL',
        customUrl: 'カスタムURL',
        metadata: 'メタデータ'
    },
    placeholder: {
        inputTitle: 'タイトルを入力',
        inputImageUrl: '画像URLを入力',
        inputUrl: 'URLを入力'
    }
}

export default translation
