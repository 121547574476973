const translation = {
    pageName: 'データラベル',
    table: {
        header: {
            id: 'ID',
            name: '名前',
            description: '説明',
            connectedFlows: '接続されたフロー',
            totalCharacters: '総文字数',
            totalChunks: '総チャンク数',
            serviceAccount: 'サービスアカウント',
            createdDate: '作成日',
            updatedDate: '更新日',
            action: 'アクション',
            loader: 'ローダー',
            splitter: 'スプリッター',
            source: 'ソース',
            chunks: 'チャンク',
            chars: '文字',
            syncingStatus: '同期状況',
            title: 'タイトル'
        },
        data: {
            notFound: 'まだドキュメントが追加されていません'
        },
        label: {
            chatFlowsUsed: '使用されたチャットフロー:'
        },
        description: '一部のファイルは処理中です。最新のステータスを取得するには更新してください。'
    },
    form: {
        search: {
            placeholder: '名前で検索'
        },
        delete: {
            title: '削除',
            description: 'ストア {{name}} を削除しますか？これにより、すべての関連するローダーとドキュメントチャンクが削除されます。'
        },
        edit: {
            title: 'データラベルを編集'
        },
        add: {
            title: '新しいデータラベルを追加',
            field: {
                serviceAccount: 'サービスアカウント',
                name: '名前',
                description: '説明',
                placeholder: 'サービスアカウントを選択してください'
            }
        },
        loaderList: {
            title: 'ドキュメントローダーを選択'
        },
        loaderDelete: {
            title: '削除',
            description: 'ローダー {{loaderName}} を削除しますか？これにより、すべての関連するドキュメントチャンクが削除されます。'
        },
        storeDelete: {
            title: '削除',
            description: 'ストア {{name}} を削除しますか？これにより、すべての関連するローダーとドキュメントチャンクが削除されます。'
        },
        chunkDelete: {
            title: '削除',
            description: 'チャンク {{chunkId}} を削除しますか？この操作は元に戻せません。'
        },
        importFile: {
            title: 'ファイルをインポート',
            description: 'ここにファイルをドラッグするか、クリックしてアップロード',
            note: {
                acceptableFile: '許可されるファイル形式: PDF; Word; PowerPoint; Excel',
                numberOfTime: '一度にインポートできるファイルは1つのみ',
                fileSize: '最大ファイルサイズ: 15MB'
            }
        },
        importDataLabel: {
            title: 'データラベルのインポート',
            description: 'ファイルをここにドラッグするか、ここをクリックしてアップロードしてください',
            note: {
                acceptableFile: 'データラベル用の正しいスキーマを持つJSONファイル形式のみを受け付けます',
                numberOfTime: '一度に1つのファイルのみインポート可能です'
            }
        }
    },
    button: {
        manageLinks: 'リンクを管理',
        refresh: '更新',
        viewChunks: 'チャンクを表示',
        addLoader: 'ドキュメントローダーを追加',
        options: 'オプション',
        previewAndProcess: 'プレビュー＆処理',
        viewAndEditChunks: 'チャンクを表示・編集',
        delete: '削除',
        editChunk: 'チャンクを編集',
        deleteChunk: 'チャンクを削除',
        importFile: 'ファイルをインポート <br /> (PDF; Word; PowerPoint; Excel)',
        download: 'ダウンロード',
        syncVectorStore: 'Azure AI Search に同期'
    },
    action: {
        selectTextSplitter: 'テキストスプリッターを選択',
        previewChunks: 'チャンクをプレビュー'
    },
    message: {
        deletedStoreSuccess: 'ストア、ローダー、および関連するドキュメントチャンクが削除されました',
        deletedStoreFailed: 'ストアの削除に失敗しました: {{error}}',
        createdDataLabelSuccess: '新しいデータラベルが作成されました',
        createdDataLabelFailed: '新しいデータラベルの追加に失敗しました: {{error}}',
        updatedDataLabelSuccess: 'データラベルが更新されました',
        updatedDataLabelFailed: 'データラベルの更新に失敗しました: {{error}}',
        deletedLoaderSuccess: 'ローダーと関連するドキュメントチャンクが削除されました',
        deletedLoaderFailed: 'ローダーの削除に失敗しました: {{error}}',
        checkMandatoryFailed: '必須項目をすべて記入してください。',
        previewChunkFailed: 'チャンクのプレビューに失敗しました:',
        onSaveAndProcessSuccess: 'ファイルが処理のために送信されました。データラベルにリダイレクトしています...',
        onSaveAndProcessFailed: 'チャンク処理に失敗しました:',
        onSaveChunksSuccess: 'チャンクの保存に成功しました',
        onSaveChunksFailed: 'チャンクの保存に失敗しました',
        updatedChunkSuccess: 'ドキュメントチャンクが正常に編集されました！',
        updatedChunkFailed: 'チャンクの編集に失敗しました:',
        deletedDocChunkSuccess: 'ドキュメントチャンクが正常に削除されました！',
        deletedDocChunkFailed: 'チャンクの削除に失敗しました:',
        importProcessing: 'データのインポートが処理中です。数分お待ちください',
        importSuccess: 'ファイルが正常にインポートされました',
        importFailed: 'ファイルのインポートに失敗しました',
        uploadPdfFailed: 'PDFドキュメントのエラー',
        uploadWordFailed: 'Wordドキュメントのエラー',
        uploadPptFailed: 'PowerPoint ドキュメントのエラー',
        uploadXlsxFailed: 'Excel ドキュメントのエラー',
        invalidJson: 'ファイルの内容が無効なJSONです',
        readJsonFileFailed: 'ファイルの読み取りに失敗しました',
        downloadDataLabelSuccess: 'データラベルのダウンロードに成功しました',
        downloadDataLabelFailed: 'データラベルのダウンロードに失敗しました',
        importDataLabelSuccess: 'データラベルのインポートに成功しました',
        importDataLabelFailed: 'データラベルのインポートに失敗しました',
        syncVectorStoreSuccess: 'Azure AI Search への同期に成功しました',
        syncVectorStoreFailed: 'Azure AI Search への同期に失敗しました',
        selectAllImageURL: 'すべてのチャンクに対して画像URLを選択しています。',
        deselectAllImageURL: 'すべてのチャンクに対して画像URLの選択を解除しています。',
        selectAllURL: 'すべてのチャンクに対してURLを選択しています。',
        deselectAllURL: 'すべてのチャンクに対してURLの選択を解除しています。',
        selectAllChunks: 'すべてのチャンクを選択:',
        onSaveLoaderSuccess: 'ローダーの保存に成功しました',
        onSaveLoaderFailed: 'ローダーの保存に失敗しました'
    },
    text: {
        characters: '文字',
        Characters: 'キャラクター',
        totalChunks: '{{currentCount}} / {{totalChunks}} チャンク',
        showChunks: '{{data}} / {{totalChunks}} チャンクを表示中',
        noChunks: 'チャンクがありません',
        note: '注:',
        url: 'URL',
        imageUrl: '画像のURL',
        selectChunk: 'すべてのチャンクを選択:'
    },
    validation: {
        fileType: '受け入れるファイル形式は次の通りです: PDF、Word、PowerPoint、Excel',
        fileSize: '15MBを超えるファイルサイズは受け付けません',
        unSupportedType: '次のファイル形式のみ受け付けます: 2007年以降のMicrosoft OfficeのPDF、Word、PowerPoint、Excel。',
        noContent: '内容のないファイルはアップロードできません',
        importDataLabel: {
            fileType: 'JSONファイルのみ受け付けます',
            wrongSchema: 'データラベルのスキーマが間違っています'
        }
    }
}

export default translation
