const translation = {
    pageName: 'Data Labels',
    table: {
        header: {
            id: 'ID',
            name: 'Name',
            description: 'Description',
            connectedFlows: 'Connected Flows',
            totalCharacters: 'Total Characters',
            totalChunks: 'Total Chunks',
            serviceAccount: 'Service Account',
            createdDate: 'Created Date',
            updatedDate: 'Updated Date',
            action: 'Action',
            loader: 'Loader',
            splitter: 'Splitter',
            source: 'Source(s)',
            chunks: 'Chunks',
            chars: 'Chars',
            syncingStatus: 'Syncing Status',
            title: 'Title'
        },
        data: {
            notFound: 'No Document Added Yet'
        },
        label: {
            chatFlowsUsed: 'Chatflows Used:'
        },
        description: 'Some files are pending processing. Please Refresh to get the latest status.'
    },
    form: {
        search: {
            placeholder: 'Search name'
        },
        delete: {
            title: 'Delete',
            description: 'Delete Store {{name}} ? This will delete all the associated loaders and document chunks'
        },
        edit: {
            title: 'Edit Data Label'
        },
        add: {
            title: 'Add New Data Label',
            field: {
                serviceAccount: 'Service Account',
                name: 'Name',
                description: 'Description',
                placeholder: 'Select Service Account'
            }
        },
        loaderList: {
            title: 'Select Document Loader'
        },
        loaderDelete: {
            title: 'Delete',
            description: 'Delete Loader {{loaderName}} ? This will delete all the associated document chunks.'
        },
        storeDelete: {
            title: 'Delete',
            description: 'Delete Store {{name}} ? This will delete all the associated loaders and document chunks.'
        },
        chunkDelete: {
            title: 'Delete',
            description: 'Delete chunk {{chunkId}} ? This action cannot be undone.'
        },
        importFile: {
            title: 'Import file',
            description: 'Drag a file here or click here to upload',
            note: {
                acceptableFile: 'Acceptable file types: PDF; Word; PowerPoint; Excel',
                numberOfTime: 'Can import only 1 file at a time',
                fileSize: 'Maximum file size: 15MB'
            }
        },
        importDataLabel: {
            title: 'Import Data Label',
            description: 'Drag a file here or click here to upload',
            note: {
                acceptableFile: 'Only accept json file format with the correct schemas for data label',
                numberOfTime: 'Can import only 1 file at a time'
            }
        }
    },
    button: {
        manageLinks: 'Manage Links',
        refresh: 'Refresh',
        viewChunks: 'View Chunks',
        addLoader: 'Add Document Loader',
        options: 'Options',
        previewAndProcess: 'Preview & Process',
        viewAndEditChunks: 'View & Edit Chunks',
        delete: 'Delete',
        editChunk: 'Edit Chunk',
        deleteChunk: 'Delete Chunk',
        importFile: 'Import File <br /> (PDF; Word; PowerPoint; Excel)',
        download: 'Download',
        syncVectorStore: 'Sync To Azure AI Search'
    },
    action: {
        selectTextSplitter: 'Select Text Splitter',
        previewChunks: 'Preview Chunks'
    },
    message: {
        deletedStoreSuccess: 'Store, Loader and associated document chunks deleted',
        deletedStoreFailed: 'Failed to delete store: {{error}}',
        createdDataLabelSuccess: 'New Data Label created',
        createdDataLabelFailed: 'Failed to add new Data Label: {{error}}',
        updatedDataLabelSuccess: 'Data Label updated',
        updatedDataLabelFailed: 'Failed to update Data Label: {{error}}',
        deletedLoaderSuccess: 'Loader and associated document chunks deleted',
        deletedLoaderFailed: 'Failed to delete loader: {{error}}',
        checkMandatoryFailed: 'Please fill in all mandatory fields.',
        previewChunkFailed: 'Failed to preview chunks:',
        onSaveAndProcessSuccess: 'File submitted for processing. Redirecting to Data Label..',
        onSaveAndProcessFailed: 'Failed to process chunking:',
        onSaveChunksSuccess: 'Save chunks successfully',
        onSaveChunksFailed: 'Failed to save chunks',
        updatedChunkSuccess: 'Document chunk successfully edited!',
        updatedChunkFailed: 'Failed to edit chunk:',
        deletedDocChunkSuccess: 'Document chunk successfully deleted!',
        deletedDocChunkFailed: 'Failed to delete chunk:',
        importProcessing: 'Importing data is being process, please wait for a few minutes',
        importSuccess: 'File imported successfully',
        importFailed: 'File import failed',
        uploadPdfFailed: 'Error PDF document',
        uploadWordFailed: 'Error Word document',
        uploadPptFailed: 'Error PowerPoint document',
        uploadXlsxFailed: 'Error Excel document',
        invalidJson: 'The file content is not valid JSON',
        readJsonFileFailed: 'Failed to read the file',
        downloadDataLabelSuccess: 'Download data label successfully',
        downloadDataLabelFailed: 'Download data label failed',
        importDataLabelSuccess: 'Import data label successfully',
        importDataLabelFailed: 'Import data label filed',
        syncVectorStoreSuccess: 'Sync to Azure AI Search successfully',
        syncVectorStoreFailed: 'Sync to Azure AI Search failed',
        selectAllImageURL: 'Your are selecting Image URL for all chunks.',
        deselectAllImageURL: 'Your are deselecting Image URL for all chunks.',
        selectAllURL: 'Your are selecting URL for all chunks.',
        deselectAllURL: 'Your are deselecting URL for all chunks.',
        selectAllChunks: 'Select for all chunks:',
        onSaveLoaderSuccess: 'Save loader successfully',
        onSaveLoaderFailed: 'Failed to save loader'
    },
    text: {
        characters: 'characters',
        Characters: 'Characters',
        totalChunks: '{{currentCount}} of {{totalChunks}} Chunks',
        showChunks: 'Showing {{data}} of {{totalChunks}} chunks',
        noChunks: 'No Chunks',
        note: 'Note:',
        url: 'URL',
        imageUrl: 'Image URL',
        selectChunk: 'Select for all chunks:'
    },
    validation: {
        fileType: 'Only accept following file types: PDF, Word, PowerPoint, Excel',
        fileSize: 'Do not accept file size large than 15MB',
        unSupportedType: 'Only accept following file types: PDF, Word, PowerPoint, Excel of Microsoft office since 2007.',
        noContent: 'Cannot upload file with no content',
        importDataLabel: {
            fileType: 'Only accept json file',
            wrongSchema: 'Wrong schemas for data label'
        }
    }
}

export default translation
